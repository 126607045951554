import { RootState } from './types'

export const initState = (): RootState => ({
  initialized: false,
  error: null,
  key: '',
  payload: '',
  activation: '',
  username: ''
})

export default initState
