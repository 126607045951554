




























































































































import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'
import { RootState } from '@/store/types'
import { EabApi } from '@/api'
import { UsernameModal } from '@/components'

@Component({
  components: { UsernameModal }
})
export default class Home extends Vue {
  @State((state: RootState) => state.key)
  public key!: string | null

  @State((state: RootState) => state.payload)
  public payload!: string | null

  @State((state: RootState) => state.activation)
  public activation!: string | null

  @State((state: RootState) => state.username)
  public username!: string | null

  public showModal = false

  public type = 'password'
  public password = ''
  public smsCode = ''
  public passwordRepeat = ''

  public loading = false
  public disableInput = false
  public showAlert = false
  public showPasswordFeedback = false
  public status = 'warning'
  public message = ''

  public feedbackMessages: string[] = []

  public feedbackMessageDictionary: Map<string, string> = new Map()

  get meetsMinimalRequirements() {
    return this.password.length >= 8 &&
      this.password.length <= 16 &&
      this.password === this.passwordRepeat &&
      this.smsCode.length === 6
      ? true
      : false
  }

  get translatedFeedbackMessage() {
    return this.feedbackMessages.map(message =>
      this.feedbackMessageDictionary.get(message)
    )
  }

  public async created() {
    this.fillFeedbackMessageDictionary()
  }

  public mounted() {
    if (this.key === undefined) {
      this.$bvModal.show('usernameModal')
    }
  }

  public fillFeedbackMessageDictionary() {
    this.feedbackMessageDictionary.set(
      'PasswordContainsUsername',
      'Het wachtwoord bevat de gebruikersnaam\n'
    )
    this.feedbackMessageDictionary.set(
      'PasswordContainsDisplayName',
      'Het wachtwoord bevat uw naam\n'
    )
    this.feedbackMessageDictionary.set(
      'PasswordMissesRequiredCharacters',
      'Het wachtwoord is niet complex genoeg. Gebruik Hoofdletters, speciale tekens en cijfers\n'
    )
    this.feedbackMessageDictionary.set(
      'PasswordTooShort',
      'Het wachtwoord is te kort\n'
    )
    this.feedbackMessageDictionary.set(
      'PasswordNotSet',
      'Wachtwoord is niet ingesteld. Neem contact op met de DHD servicedesk\n'
    )
    this.feedbackMessageDictionary.set(
      'TimeExpired',
      'De toegestane tijd om een wachtwoord in te stellen is verlopen\n'
    )
  }

  public showPassword() {
    this.type === 'password' ? (this.type = 'text') : (this.type = 'password')
  }

  public async setPassword() {
    this.loading = true
    this.disableInput = true
    this.setAlert(false)
    try {
      const base64Password = btoa(this.password)
      const isValidSmsCode = await EabApi.checkSmsCode(this.key, this.smsCode)
      if (isValidSmsCode) {
        const passwordFeedback = await EabApi.setPassword(
          base64Password,
          this.key,
          this.payload,
          this.activation,
          this.smsCode
        )

        this.feedbackMessages = passwordFeedback
        if (passwordFeedback.indexOf('PasswordChanged') > -1) {
          this.password = ''
          this.smsCode = ''
          this.passwordRepeat = ''
          this.showPasswordFeedback = false
          this.setAlert(
            true,
            'success',
            'Uw wachtwoord is ingesteld. U wordt nu doorverwezen naar MijnDHD.'
          )

          setTimeout(this.timerEnd, 5000)
        } else {
          this.showPasswordFeedback = true
          this.setAlert(true, 'danger', 'Uw wachtwoord is ongeldig:')
          this.disableInput = false
        }
      } else {
        this.showPasswordFeedback = false
        this.setAlert(true, 'danger', 'Uw verificatiecode is ongeldig.')
        this.disableInput = false
      }
    } catch (error) {
      this.showPasswordFeedback = false
      this.setAlert(true, 'danger', error.title)
    } finally {
      this.loading = false
    }
  }

  public timerEnd() {
    window.location.href = 'https://mijn.dhd.nl'
  }

  public setAlert(show: boolean, status?: string, message?: string) {
    this.showAlert = show
    status ? (this.status = status) : (this.status = '')
    message ? (this.message = message) : (this.message = '')
  }

  public hasValidLength(password: string) {
    if (password.length < 8) {
      return null
    } else if (8 <= password.length && password.length <= 16) {
      return true
    } else {
      return false
    }
  }

  public hasValidCodeLength(smsCode: string) {
    if (smsCode.length === 6) {
      return true
    } else {
      return null
    }
  }

  public isMatchingPassword() {
    if (this.password !== this.passwordRepeat) {
      if (this.password.length > this.passwordRepeat.length) {
        return null
      } else {
        return false
      }
    } else if (this.password === this.passwordRepeat) {
      if (this.hasValidLength(this.password)) {
        return true
      } else {
        return null
      }
    }
  }
}
