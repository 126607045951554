import {
  ActionTree,
  ActionContext,
  MutationTree,
  GetterTree,
  Store
} from 'vuex'

// State
export interface RootState {
  initialized: boolean
  error: string | null
  key: string
  payload: string
  activation: string
  username: string
}

// Actions
export type RootActionContext = ActionContext<RootState, RootState>

export const enum ActionTypes {
  INITIALIZE_STATE = 'initializeState'
}

export interface RootActions extends ActionTree<RootState, RootState> {
  [ActionTypes.INITIALIZE_STATE]: (
    this: Store<RootState>,
    context: RootActionContext
  ) => void
}

// Mutations
export const enum MutationTypes {
  SET_INITIAL_STATE = 'SET_INITIAL_STATE',
  SET_ERROR = 'SET_ERROR'
}

export interface RootMutations extends MutationTree<RootState> {
  [MutationTypes.SET_INITIAL_STATE]: (
    state: RootState,
    values: {
      key: string
      payload: string
      activation: string
      username: string
    }
  ) => void
  [MutationTypes.SET_ERROR]: (state: RootState, error: string) => void
}

// Getters
export const enum GetterTypes {}

export type RootGetters = GetterTree<RootState, RootState>
