import { CancelToken } from 'axios'
import { requestApi } from './utils'

export class EabApi {
  public static checkActivationLink(
    key: string | null,
    payload: string | null,
    activation: string | null,
    cancelToken?: CancelToken
  ) {
    return requestApi<boolean | null>({
      url: `/Activation/CheckActivationLink?key=${key}&payload=${payload}&activation=${activation}`,
      cancelToken
    })
  }

  public static sendPasswordLink(
    username: string | null,
    cancelToken?: CancelToken
  ) {
    return requestApi<boolean | null>({
      method: 'GET',
      url: `/Activation/sendPasswordLink?username=${username}`,
      cancelToken
    })
  }

  public static checkSmsCode(
    key: string | null,
    smsCode: string | null,
    cancelToken?: CancelToken
  ) {
    return requestApi<boolean | null>({
      method: 'GET',
      url: `/Activation/checkSmsCode?key=${key}&smscode=${smsCode}`,
      cancelToken
    })
  }

  public static setPassword(
    input: string,
    key: string | null,
    payload: string | null,
    activation: string | null,
    smsCode: string | null,
    cancelToken?: CancelToken
  ) {
    return requestApi<string[]>({
      method: 'POST',
      url: `/Activation/setPassword?input=${input}&key=${key}&payload=${payload}&activation=${activation}&smscode=${smsCode}`,
      cancelToken
    })
  }
}

export default EabApi
