






















import Vue from 'vue'

import { Component, Prop } from 'vue-property-decorator'

@Component
export default class AppScreen extends Vue {
  @Prop({ default: '', type: String })
  public icon!: string

  @Prop({ default: 'above', type: String })
  public iconPlacement!: string

  @Prop({ type: Boolean })
  public spinner!: boolean

  @Prop({ type: String })
  public theme!: string

  get classes() {
    const classes = ['app-screen']

    if (this.theme) {
      classes.push(`app-screen--${this.theme}`)
    }

    return classes
  }
}
