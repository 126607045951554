import { default as Axios, AxiosRequestConfig, AxiosResponse } from 'axios'

export async function request<T>(
  config: AxiosRequestConfig
): Promise<AxiosResponse<T>> {
  const response = await Axios.request<T>(config)

  return response
}

export async function requestApi<T>(config: AxiosRequestConfig): Promise<T> {
  try {
    const response = await Axios.request<T>({
      baseURL: '/api/',
      responseType: 'json',
      ...config
    })

    return response.data
  } catch (error) {
    if (Axios.isCancel(error)) throw error

    const { response } = error
    if (response.status === 500 || response.status === 404) {
      throw new Error('Er ging iets mis met het ophalen van de data')
    } else {
      throw new Error(response.data.message)
    }
  }
}