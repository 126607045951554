






































































import { Component, Vue } from 'vue-property-decorator'
import { EabApi } from '@/api'
import { validationMixin } from 'vuelidate'
import {
  maxLength,
  minLength,
  required
} from 'vuelidate/lib/validators'

const alphaNumDotHyphen = (value: string) =>
  value.match(/^[^\s]*$/i) ? true : false

@Component({
  mixins: [validationMixin],
  validations: {
    form: {
      username: {
        required,
        alphaNumDotHyphen,
        minLength: minLength(3),
        maxLength: maxLength(20)
      }
    }
  }
})
export default class UsernameModal extends Vue {
  public loading = false
  public result: boolean | null = null
  public error: string | null = null

  public form: any = {
    username: this.$attrs.username ? this.$attrs.username : null
  }

  public validateState(value: any) {
    return (this as any).$v.form[value].$error ? false : null
  }

  public async sendPasswordLink() {
    try {
      this.loading = true
      const success = await EabApi.sendPasswordLink(this.form.username)
      if (success) {
        this.result = true
        this.loading = false
      } else {
        this.result = false
        this.error = 'Er is iets fout gegaan. Neem contact op met DHD'
        this.loading = false
      }
    } catch (error) {
      this.error = error
      this.loading = false
    }
  }
}
