import { RootActions, MutationTypes, ActionTypes } from './types'
import { EabApi } from '@/api'

export const actions: RootActions = {
  async [ActionTypes.INITIALIZE_STATE]({ commit }) {
    try {
      const values = JSON.parse(
        window.localStorage.getItem('activationparams') as string
      )

      if (values.key === undefined) {
        commit(MutationTypes.SET_INITIAL_STATE, values)
      } else {
        const isValidActivation = await EabApi.checkActivationLink(
          values.key,
          values.payload,
          values.activation
        )

        if (isValidActivation) {
          commit(MutationTypes.SET_INITIAL_STATE, values)
        } else {
          const message = 'Uw activatielink is ongeldig.'
          commit(MutationTypes.SET_ERROR, message)
        }
      }
    } catch (error) {
      commit(MutationTypes.SET_ERROR, error.message)
    }
  }
}

export default actions
