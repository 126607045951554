/* eslint-disable @typescript-eslint/class-name-casing */
export interface DHD_ENV_VARIABLES {
  VUE_APP_CLIENTID: string
  VUE_APP_INSTANCE: string
  VUE_APP_DOMAIN: string
  VUE_APP_MIJNDHD: string
  VUE_APP_CMS_API: string
  VUE_APP_USER: string
  VUE_APP_USER_API: string
  VUE_APP_FILE: string
  VUE_APP_FILE_API: string
  VUE_APP_BUILD_VERSION: string
  VUE_APP_I18N_LOCALE: string
  VUE_APP_I18N_FALLBACK_LOCALE: string
}

/**
 * In local development mode the `.env.development` variables are read
 * In all other modes the release variables, set during the continuous deployment release step, are read
 */
export const getEnvironmentVariable = (name: keyof DHD_ENV_VARIABLES): string =>
  process.env.NODE_ENV === 'development'
    ? process.env[name]
    : window.DHD_ENV_VARIABLES[name]
