import Vue from 'vue'
import { date, time } from 'dhd-library/src/filters/time'

type nullableString = string | null

export function number(value?: nullableString, decimals = 0): string {
  if (value === undefined || value === null) return ''
  return parseFloat(value)
    .toFixed(decimals)
    .replace('.', ',')
    .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
}

export function euro(value?: nullableString, decimals = 2): string {
  if (value === undefined || value === null) return ''
  return `€ ${number(value, decimals)}`
}

export function percent(value?: nullableString, decimals = 1): string {
  if (value === undefined || value === null) return ''
  return `${number(value, decimals)}%`
}

export function boolean(value: boolean): string {
  if (value) return 'Ja'
  else return 'Nee'
}

Vue.filter('number', number)
Vue.filter('euro', euro)
Vue.filter('percent', percent)
Vue.filter('date', date)
Vue.filter('time', time)
Vue.filter('boolean', boolean)
