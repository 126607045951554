



















import { Component, Watch, Vue } from 'vue-property-decorator'
import { CmsApi } from '@/api'
import LockedMenu from '@/components/LockedMenu.vue'
import { getEnvironmentVariable } from '@/utils/environment'

@Component({
  components: { LockedMenu }
})
export default class AppLayout extends Vue {
  public openLeft = false
  public openRight = false

  public appName = 'Account activatie'

  public navItems = []

  public setStatusFromLeft(status: boolean) {
    this.openRight = false
    this.openLeft = status
  }

  public version = 'default'

  public mounted() {
    this.version = getEnvironmentVariable('VUE_APP_BUILD_VERSION')
  }

  public closeBars() {
    this.openLeft = this.openRight = false
  }

  @Watch('openLeft')
  public bodyCover() {
    if (this.openLeft) {
      document.querySelector('body')?.classList.add('scrollbar--hide')
    } else {
      document.querySelector('body')?.classList.remove('scrollbar--hide')
    }
  }

  get cmsApi() {
    return new CmsApi()
  }
}
