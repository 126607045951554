import { RootMutations, MutationTypes } from './types'

export const mutations: RootMutations = {
  [MutationTypes.SET_INITIAL_STATE]: (
    state,
    { key, payload, activation, username }
  ) => {
    state.initialized = true
    state.key = key
    state.payload = payload
    state.activation = activation
    state.username = username
  },
  [MutationTypes.SET_ERROR]: (state, error) => {
    state.initialized = true
    state.error = error
  }
}

export default mutations
