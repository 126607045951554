






















import { Component, Watch, Vue } from 'vue-property-decorator'
import { State, Action } from 'vuex-class'

import { RootState, ActionTypes } from '@/store/types'
import { AppScreen, AppLayout } from '@/components'
import { Activation } from '@/models'

@Component({
  components: { AppLayout, AppScreen }
})
export default class App extends Vue {
  @State((state: RootState) => state.initialized)
  public initialized!: boolean

  @State((state: RootState) => state.error)
  public error!: string | null

  @Action(ActionTypes.INITIALIZE_STATE)
  public initializeState!: () => Promise<any>

  public showAlert = false
  public variant = 'primary'
  public message = ''

  public async created() {
    if (this.$route.query) {
      const query: Activation = {
        key: this.$route.query.key as string,
        payload: this.$route.query.payload as string,
        activation: this.$route.query.activation as string,
        username: this.$route.query.username as string
      }

      window.localStorage.setItem('activationparams', JSON.stringify(query))

      await this.initialize()
    }
  }

  public async initialize() {
    if (!this.initialized) {
      await this.initializeState()
    }
  }
}
