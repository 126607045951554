import { render, staticRenderFns } from "./UsernameModal.vue?vue&type=template&id=c908ec48&scoped=true&"
import script from "./UsernameModal.vue?vue&type=script&lang=ts&"
export * from "./UsernameModal.vue?vue&type=script&lang=ts&"
import style0 from "./UsernameModal.vue?vue&type=style&index=0&id=c908ec48&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c908ec48",
  null
  
)

export default component.exports