import { requestApi } from './utils'
import { CmsApi as CMSApi } from 'dhd-library/src/index'

export class CmsApi implements CMSApi {
  public async getInfoBar() {
     return requestApi<any>({
      method: 'GET',
      url: `/infobar`,
    })
  }
  
  public getInformatieBalk(parameters: { ProductId: string }) {
    return Promise.reject(
      `getInformatieBalk is called with ${parameters} should not be used`
    )
  }
}

export default CmsApi
